.home-container {
    display: flex;
    height: 100vh; // Full height of the viewport

    .main-content {
        flex: 1; // Take remaining space
        display: flex;
        flex-direction: column; // Stack TopBar and content area

        .content-area {
            flex: 1; // Take up available space below TopBar
            display: flex;
            justify-content: center; // Center the Login component
            align-items: center; // Center vertically
        }
    }
}
