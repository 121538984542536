* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

.body-padding {
  padding: 0px !important;
}

.content-Setting {
  margin-left: 50px;
  margin-right: 50px;
}

.home-section1 {
  height: 100vh !important;
  background: whitesmoke !important;
  position: relative;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  padding-top: 1rem;
}

.sidebar.close~.home-section1 {
  left: 78px;
  width: calc(100% - 78px);
}

.home-section1 .home-content {
  height: 60px;
  display: flex;
  align-items: center;
}

.home-section1 .home-content .bx-menu,
.home-section1 .home-content .text {
  color: #11101d;
  font-size: 35px;
}

.home-section1 .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}

.home-section1 .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

.loginhere-link {
  font-weight: 700;
  color: #186096;
  text-decoration: none;
}
.form-title{
  color: #575a5d;
}


@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}


.image-height {
  max-height: 50px;
}

.heading-content {
  display: flex;
  background-color: #0e2443;
  align-items: center;
  border-radius: 2px;
  margin-bottom: 1rem;
}

.heading-content h2 {
  margin-bottom: 0px;
  color: #fff;
  font-size: 1.3rem;
  padding: 0.3rem 0 0.3rem 0.8rem;
}

table th{
  background-color: #315383 !important;
  color: #fff !important;
}

.error-image{
  width: 100% !important;
}

.home-section {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's above other content */
  background-color: #fff; /* Optional: Set a background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
}
.bg_viewpopup {
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.ant-picker-clear{
  font-size: 19px;
}
.ant-select .ant-select-clear{

  top: 42% !important;
  right: 12px  !important;
}
.anticon-close-circle{
  font-size: 19px;
}