
.home-section {

    position: relative;
    background: #ffffff;
    height: auto;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
    padding: 1rem;
  }
  .sidebar.close ~ .home-section {
    left: 78px;
    width: calc(100% - 78px);
  }
  .home-section .home-content {
    height: auto;
    display: flex;
    align-items: center;
  }
  .home-section .home-content .bx-menu,
  .home-section .home-content .text {
    color: #11101d;
    font-size: 35px;
  }
  .home-section .home-content .bx-menu {
    margin: 0 15px;
    cursor: pointer;
  }
  .home-section .home-content .text {
    font-size: 26px;
    font-weight: 600;
  }
  @media (max-width: 420px) {
    .sidebar.close .nav-links li .sub-menu {
      display: none;
    }
  }


  .image-height{
    max-height: 50px;
  }