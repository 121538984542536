/* @extend display-flex; */
display-flex,
.display-flex,
.display-flex-center {
  display: flex;
  display: -webkit-flex;
}

/* @extend list-type-ulli; */
list-type-ulli {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Montserrat-300 - latin */
@font-face {
  font-family: math;
  font-style: normal;
  font-weight: 300;
  // src: url("../fonts/montserrat/Montserrat-Light.ttf");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: math;
  font-style: normal;
  font-weight: 400;
  // src: url("../fonts/montserrat/Montserrat-Regular.ttf");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: math;
  font-style: italic;
  font-weight: 400;
  // src: url("../fonts/montserrat/Montserrat-Italic.ttf");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: math;
  font-style: normal;
  font-weight: 500;
  // src: url("../fonts/montserrat/Montserrat-Medium.ttf");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: math;
  font-style: normal;
  font-weight: 600;
  // src: url("../fonts/montserrat/Montserrat-SemiBold.ttf");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: math;
  font-style: normal;
  font-weight: 700;
  // src: url("../fonts/montserrat/Montserrat-Bold.ttf");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: math;
  font-style: italic;
  font-weight: 700;
  // src: url("../fonts/montserrat/Montserrat-BoldItalic.ttf");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: math;
  font-style: italic;
  font-weight: 900;
  // src: url("../fonts/montserrat/montserrat-v12-latin-900.ttf"), url("../fonts/montserrat/montserrat-v12-latin-900.eot") format("embedded-opentype"), url("../fonts/montserrat/montserrat-v12-latin-900.svg") format("woff2"), url("../fonts/montserrat/montserrat-v12-latin-900.woff") format("woff"), url("../fonts/montserrat/montserrat-v12-latin-900.woff2") format("truetype");
}

a:focus,
a:active {
  text-decoration: none;
  outline: none;
  transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -webkit-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
}

input,
select,
textarea {
  outline: none;
  appearance: unset !important;
  -moz-appearance: unset !important;
  -webkit-appearance: unset !important;
  -o-appearance: unset !important;
  -ms-appearance: unset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  margin: 0;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  -ms-box-shadow: none !important;
}

input[type=checkbox] {
  appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  -webkit-appearance: checkbox !important;
  -o-appearance: checkbox !important;
  -ms-appearance: checkbox !important;
}

input[type=radio] {
  appearance: radio !important;
  -moz-appearance: radio !important;
  -webkit-appearance: radio !important;
  -o-appearance: radio !important;
  -ms-appearance: radio !important;
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

p {
  margin-bottom: 0px;
  font-size: 15px;
  color: #777;
}

h2 {
  line-height: 1.66;
  margin: 0;
  padding: 0;
  font-weight: 900;
  color: #222;
  font-family: math;
  font-size: 24px;

  text-align: center;
  margin-bottom: 40px;
}

.clear {
  clear: both;
}

.container1 {

  position: relative;
  margin: 0 auto;
}

.display-flex {
  justify-content: space-between;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}

.display-flex-center {
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.signup-content1 {
  background: #fff;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  padding: 23px 85px;

}

.form-group {
  position: relative;
  // overflow: hidden;
  margin-bottom: 20px;
}

.fa-circle-info:before,
.fa-info-circle:before {
  content: "\f05a";
  position: absolute;
  top: 27px;
  right: 21px;
}

.fa-circle-check {
  position: absolute;
  right: 21px;
  top: 27px;


}

.fa-circle-check {
  position: absolute;
}


.error-message {
  color: red;
  font-size: 12px;
}

.form-input {
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 17px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: #222;
}

.form-input::-webkit-input-placeholder {
  color: #999;
}

.form-input::-moz-placeholder {
  color: #999;
}

.form-input:-ms-input-placeholder {
  color: #999;
}

.form-input:-moz-placeholder {
  color: #999;
}

.form-input::-webkit-input-placeholder {
  font-weight: 500;
}

.form-input::-moz-placeholder {
  font-weight: 500;
}

.form-input:-ms-input-placeholder {
  font-weight: 500;
}

.form-input:-moz-placeholder {
  font-weight: 500;
}

.form-input:focus {
  border: 1px solid transparent;
  -webkit-border-image-source: -webkit-linear-gradient(to right, #9face6, #74ebd5);
  -moz-border-image-source: -moz-linear-gradient(to right, #9face6, #74ebd5);
  -o-border-image-source: -o-linear-gradient(to right, #9face6, #74ebd5);
  border-image-source: linear-gradient(to right, #9face6, #74ebd5);
  -webkit-border-image-slice: 1;
  border-image-slice: 1;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.form-input:focus::-webkit-input-placeholder {
  color: #222;
}

.form-input:focus::-moz-placeholder {
  color: #222;
}

.form-input:focus:-ms-input-placeholder {
  color: #222;
}

.form-input:focus:-moz-placeholder {
  color: #222;
}

.form-submit {
  width: 100%;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 17px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  color: #fff;

  border: none;
  background-image: linear-gradient(to left, #74ebd5, #9face6);
}

input[type=checkbox]:not(old) {
  width: 2em;
  margin: 0;
  padding: 0;
  font-size: 1em;
  display: none;
}

input[type=checkbox]:not(old)+label {
  display: inline-block;
  margin-top: 7px;
  margin-bottom: 25px;
}

input[type=checkbox]:not(old)+label>span {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 15px;
  margin-bottom: 3px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  -ms-border-radius: 2px;
  background: white;
  background-image: -moz-linear-gradient(white, white);
  background-image: -ms-linear-gradient(white, white);
  background-image: -o-linear-gradient(white, white);
  background-image: -webkit-linear-gradient(white, white);
  background-image: linear-gradient(white, white);
  vertical-align: bottom;
}

input[type=checkbox]:not(old):checked+label>span {
  background-image: -moz-linear-gradient(white, white);
  background-image: -ms-linear-gradient(white, white);
  background-image: -o-linear-gradient(white, white);
  background-image: -webkit-linear-gradient(white, white);
  background-image: linear-gradient(white, white);
}

input[type=checkbox]:not(old):checked+label>span:before {
  content: '\f26b';
  display: block;
  color: #222;
  font-size: 11px;
  line-height: 1.2;
  text-align: center;
  font-family: math;
  font-weight: bold;
}

.label-agree-term {
  font-size: 12px;
  font-weight: 600;
  color: #555;
}

.term-service {
  color: #555;
}

.loginhere {
  color: #555;
  font-weight: 500;
  text-align: center;
  margin-top: 38px;
  margin-bottom: 5px;
}

.loginhere-link {
  font-weight: 700;
  color: #222;
}



.field-icon {
  float: right;
  margin-right: 17px;
  margin-top: -32px;
  position: relative;
  z-index: 2;
  color: #555;
}


.form-input.error {
  border: 2px solid red;
  /* Red border for invalid fields */
}





.error-icon {
  color: red;
  /* Red icon for error */
  margin-left: 5px;
}


.register-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .container1 {
    width: calc(100% - 40px);
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .signup-content1 {
    padding: 50px 25px;
  }
}

/*# sourceMappingURL=style.css.map */