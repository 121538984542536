.home-section {
  position: relative;
  background: #e4e9f7;
  height: auto;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
}

.sidebar.close~.home-section {
  left: 78px;
  width: calc(100% - 78px);
}

.home-section .home-content {
  height: auto;
  display: flex;
  align-items: center;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}

.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}

.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

.add-button {
  text-align: end;
  margin-bottom: 1rem;

  button {
    background-color: #1e3a63;
    box-shadow: none;
    border-radius: 4px;
    padding: 0.9rem;

    i {
      height: 1.5rem;
      width: 1.5rem;
      font-size: 1.5rem;
    }

    span {
      font-size: 15px;
    }
  }
}

.button-content {
  background-color: #1e3a63;
  box-shadow: none;
  border-radius: 4px;
  padding: 1.1rem;
  margin-right: 1rem;

  i {
    height: 1.1rem;
    width: 1.1rem;
    font-size: 1.1rem;
  }

  span {
    font-size: 15px;
  }
}

@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}

.image-height {
  max-height: 50px;
}

.main-contents {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}



.submit-container1{
  text-align: end;
}
.submit-button1 {
  width: auto;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  color: #fff;

  border: none;
  background-color: #1e3a63;
  box-shadow: none;
  border-radius: 4px;
  &:hover{
      background: #4096ff;
      color: #fff;
  }
}
.submit-button2 {
  background-color: rgb(183, 49, 39) !important;
  margin-left: 5px;
  width: auto;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  color: #fff;

  border: none;

  box-shadow: none;
  border-radius: 4px;
  &:hover{
      background: #ff7875 !important;
      color: #fff;
  }
}
.submit-button3{
  width: 200px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  color: #fff;

  border: none;
  background-color: #1e3a63;
  box-shadow: none;
  border-radius: 4px;
  &:hover{
      background: #4096ff;
      color: #fff;
  }
}

.signup-content-model {
  background: #fff;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  padding: 20px 30px;

}

.signup-content-model1 {
  background: #fff;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  padding:14px 23px;

}

.form-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-group1 {
  display: flex;
  gap: 8px; /* Space between input and search button */
}
.add-user-btn{
  padding: 18px;
}

.flex-buttons{
  display: flex;
  align-items: center;
  text-decoration: none;
}

// @media (max-width: 1240px) {
//   .role-button{
//     margin-top: 10px;
//   }
// }

.custom-table1 .ant-table-tbody > tr > td.ant-table-cell:nth-child(6) {
  text-align: left !important; /* Align Action column data to the left */
.ant-table-cell{
  width: 200px;
}
}

.custom-table1{
.ant-table-cell{
  width: 200px;
}
}

.Search-field1{
  display: flex;
    gap: 0px;
    flex-direction: column;
    label{
      font-weight: 500;
      color: #727678;
      text-align: left;
    }
}