.signup-content2 {
    background: #fff;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    padding: 23px 11px;

  }

  .graph-card{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
border: none;}



.recharts-sector:focus {
  outline: none;
}

.hidden-table{
  display: none;
}